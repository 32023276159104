
import { General, FilterPhases, Page } from "@/models";
import { getCookie, setCookie } from 'typescript-cookie'
import { PAGES_KEYS } from "@/shared/pages-keys";

interface PayloadFilterPhases {
    data: FilterPhases
}

export const EmptyPagesState:Page[]  = [
    { name:'activities', uuid: PAGES_KEYS.ACTIVITIES, showHelp: true},
    { name:'activity', uuid: PAGES_KEYS.ACTIVITY, showHelp: true},
    { name:'activityHistoral', uuid: PAGES_KEYS.ACTIVITYHISTORAL, showHelp: true},
    { name:'bills', uuid: PAGES_KEYS.BILLS, showHelp: true},
    { name:'home', uuid: PAGES_KEYS.HOME, showHelp: true},
    { name:'positions_results', uuid: PAGES_KEYS.POSITIONS_RESULTS, showHelp: true},
    { name:'activities_history_filter', uuid: PAGES_KEYS.ACTIVITIES_HISTORY_FILTER, showHelp: true},
]

export const getDefaultState = (): General => ({
    filterPhase: { selected: [], phases: []},
    showHelp: false,
    pages: EmptyPagesState,
    headerCircle: {
        ionTitleJustify: 'center'
    }
 });
  
const state:General = JSON.parse(JSON.stringify(getDefaultState()))

const getters = {
    getFilterPhases: () => state.filterPhase,
    getPage: (state:General) => (pageKey: string) =>state.pages.find((_page:Page) =>  _page.uuid === pageKey)
};
 
const actions = {};
 
const mutations = {
    checkPages(state: General){
        const {pages} = state
        for (const [key, value] of Object.entries(PAGES_KEYS)) {
            const pageCookie = getCookie(`page-${value}`)
            const pageIndex = pages.findIndex((_page: Page)=> _page.uuid === value)

            if ( pageCookie !== undefined ){
                pages[pageIndex].showHelp = !!+pageCookie
                continue;
            }

            setCookie(`page-${value}`, 1)
        }

        state.pages = pages
    },

    setHeaderCircleTitleAlignment(state:General, payload: string){
        switch (payload) {
            case "BILLS":
            case "ACTIVITIES":
              state.headerCircle.ionTitleJustify = 'start'
              break;
            default:
              state.headerCircle.ionTitleJustify = 'center'
          }
    },

    setPhases(state: General, payload: PayloadFilterPhases) {
        state.filterPhase = payload.data
    },

    setShowHelp(state: General, payload: boolean){
        state.showHelp = payload
    },

    updateSelectedPhases(state:General, selectedPhases: string[]){
        state.filterPhase.selected = selectedPhases
    },

    updatePage(state:General, pageKey: string){
        state.pages = state.pages.map((page:Page)=> pageKey ===  page.uuid ? { ...page, showHelp: false} : page
        )
    },
    
    resetAllSettings(state:General) {
        Object.assign(state, getDefaultState())
    }
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}