import { AxiosCall } from '@/models';
import { AxiosResponse } from 'axios';
import { ref, watchEffect } from "vue";

const useFetchAndLoad=()=>{
  const loading = ref(false);
  let controller:AbortController;

  const callEndpoint = async (axiosCall: AxiosCall<any>) => {
    if (axiosCall.controller) controller = axiosCall.controller;
     loading.value = true;
    let result = {} as AxiosResponse<any>;
    try {
      result = await axiosCall.call;
    } catch (err: any) {
      loading.value = false;
      throw err;
    }
    loading.value = false;
    return result;
  };

  const cancelEndpoint = () => {
  loading.value = false;
    controller && controller.abort();
  };

  watchEffect(()=>{
    return () => cancelEndpoint();
  })

  return { loading, callEndpoint };

}
export default useFetchAndLoad;
