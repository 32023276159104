import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return _withDirectives((_openBlock(), _createBlock(_component_ion_list, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, {
        lines: "none",
        button: "",
        onClick: _withModifiers(_ctx.handleGoToHelp, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.messages.USER_MENU.HELP), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.helpCircleOutline,
            size: "large"
          }, null, 8, ["icon"])
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_ion_item, {
        lines: "none",
        button: "",
        style: {"display":"none"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.messages.USER_MENU.SILENCE), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.musicalNotesOutline,
            size: "large"
          }, null, 8, ["icon"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, {
        lines: "none",
        button: "",
        onClick: _withModifiers(_ctx.handleGoToSofia, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.messages.USER_MENU.GO_TO_SOFIAXT), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.powerOutline,
            size: "large"
          }, null, 8, ["icon"])
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_ion_item, {
        lines: "none",
        style: _normalizeStyle(Boolean(_ctx.welcome?.studentName) ? '' : 'display:none')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.welcome?.studentName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.personCircle,
            size: "large"
          }, null, 8, ["icon"])
        ]),
        _: 1
      }, 8, ["style"])
    ]),
    _: 1
  }, 512)), [
    [_vShow, !_ctx.hide]
  ])
}