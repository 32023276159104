import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-642c66dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", { id: _ctx.triggerId }, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ], 8, _hoisted_1),
    _createVNode(_component_ion_popover, {
      class: _normalizeClass(_ctx.props.class),
      size: _ctx.size,
      side: "top",
      "is-open": _ctx.props.isOpen,
      "dismiss-on-select": true,
      "backdrop-dismiss": true,
      trigger: _ctx.triggerId,
      "show-backdrop": true,
      alignment: _ctx.props.alignment,
      onDidDismiss: _ctx.close
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_content, { class: "ion-padding" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.props.message
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class", "size", "is-open", "trigger", "alignment", "onDidDismiss"])
  ], 64))
}