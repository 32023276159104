import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_PositionResultsModal = _resolveComponent("PositionResultsModal")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, {
            slot: "bottom",
            class: "mainMenu"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "tab1",
                class: _normalizeClass(_ctx.isTabActive('activities') ? 'tab-active' : ''),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleGoTo('/tabs/activities')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa-solid fa-grid-2",
                    class: "tab-icon-bottom"
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_ion_tab_button, {
                class: _normalizeClass(_ctx.isTabActive('dash') ? 'tab-active' : ''),
                tab: "tab2",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleGoTo('/tabs/dashboard')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa-solid fa-house",
                    class: "tab-icon-bottom"
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_ion_tab_button, {
                id: 13,
                tab: "tab3",
                class: _normalizeClass(_ctx.isTabActive('POSITIONS_RESULTS') ? 'tab-active' : ''),
                onClick: _ctx.handleOpenModal
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa-solid fa-medal",
                    class: "tab-icon-bottom"
                  })
                ]),
                _: 1
              }, 8, ["class", "onClick"]),
              _createVNode(_component_ion_tab_button, { onClick: _ctx.openPopover }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa-solid fa-circle-user",
                    class: "tab-icon-bottom"
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_PositionResultsModal, { ref: "refModalPositionsResults" }, null, 512)
    ]),
    _: 1
  }))
}