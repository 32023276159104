
import { Welcome } from "@/models";
import { createWelcomeAdapter} from "@/adapters/welcome.adapter"
export const WelcomeEmptyState: Welcome = {
    _Goal: 0,
    bills: [],
    enrollmentId: '',
    family: [],
    familyImage: '',
    goal: '',
    history: '',
    id:'',
    roletofulfill: '',
    studentName: ''
};

const state = JSON.parse(JSON.stringify(WelcomeEmptyState))

const getters = {
    getAllData(){
        return state
    },
    getBills() {
        return state.welcome.bills
    },
    getFamily() {
        return state.welcome.family
    },
};
 
const actions = {};
 
const mutations = {
    setData(state:any, payload: any){
        Object.assign(state, createWelcomeAdapter(payload.data))
    },
    reset(state:any) {
        Object.assign(state, WelcomeEmptyState)
      }
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

