import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HOME',
    redirect: '/tabs/dashboard'
  },
  {
    path: "/help",
    name: "HELP",
    component: () => import('@/views/HelpPage.vue')
  },
  {
    path: "/history/activities",
    name: "HISTORY_ACTIVITIES",
    component: () => import('@/views/HistoryActivitiesPage.vue')
  },
  {
    path: '/positions',
    name: 'POSITIONS_RESULTS',
    component: () => import('@/views/PositionResultsPage.vue'),
  },
  {
    path: '/validate/activities/:id',
    name: 'VALIDATE_ACTIVITY',
    component: () => import('@/views/ValidateActivityPage.vue'),
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        name: 'DASHBOARD',
        redirect: '/tabs/dashboard'
      },
      {
        path: "/bills",
        name: "BILLS",
        component: () => import('@/views/BillsPage.vue')
      },
      {
        path: 'activities',
        name: 'ACTIVITIES',
        component: () => {
          if(localStorage.getItem("typeChallenge") == "2"){
            return import('@/views/ActivitiesPage.vue')
          }else{
            return import('@/views/ActivitiesPageB.vue')
          }
          
        }
      },
      {
        path: 'validator_activities',
        name: 'VALIDATOR_ACTIVITIES',
        component: () => import('@/views/ActivitiesPage.vue'),
      },
      {
        path: 'validate/activities',
        name: 'VALIDATE_ACTIVITIES',
        component: () => import('@/views/ValidateActivitiesPage.vue'),
      },
      {
        path: 'activities/:id',
        name: 'ACTIVITY',
        component: () =>{
          if(localStorage.getItem("typeChallenge") == "3"){
            return import('@/views/ActivityPage.vue')
          }else{
            return import('@/views/ActivityPageB.vue')
          }
        } 
      },
      {
        path: 'dashboard/:id?/:type?',
        name: 'DASH',
        component: () => import('@/views/DashboardPage.vue'),
        props: true
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  const {name, params} = to;

  switch (String(name).toUpperCase()) {
    case "DASHBOARD":
    case "HOME":
    case "BILLS":
    case "DASH":
    case "HELP":
    case "ACTIVITIES":
    case "HISTORY_ACTIVITIES":
    case "POSITIONS_RESULTS":
        if(params.id){
          localStorage.setItem('User-Enrollment-Id', `${params.id}`)
        }
        if(!localStorage.getItem('User-Enrollment-Id')){
          window.location.href = 'https://sofiaxt.com/';
        }
        break;
    case "ACTIVITY":
      if((from?.name !== 'ACTIVITIES' && from?.name !== 'HISTORY_ACTIVITIES' )||  !params?.id){
        return {path: '/tabs/activities', replace: true};
        
      }

      if(!localStorage.getItem('User-Enrollment-Id')){
        window.location.href = 'https://sofiaxt.com/';
      }

      break;
    case "VALIDATE_ACTIVITY":
      break;
      
    default:
      if(!localStorage.getItem('User-Enrollment-Id')){
         window.location.href = 'https://sofiaxt.com/';
      }

      break;
  }

})

export default router
