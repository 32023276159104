import { ActivityValidator } from '@/models';

interface ValidateActivity {
  description: string;
  logo: string;
  title: string;
  value: string;
}

interface ValidateReport {
  comments: string;
  date: string;
  photo: string;
  status: number;
  studentName: string;
  time: string;
}

export interface AdapterActivityValidator {
  activity: ValidateActivity;
  report: ValidateReport;
}

export const emptyAdapterActivityValidator:AdapterActivityValidator = {
  activity: {
    description:'',
    logo: '',
    title: '',
    value: ''
  },
  report: {
    comments: '',
    date: '',
    photo: '',
    status: 0,
    studentName: '',
    time: ''
  }
}

export const createActivityValidatorAdapter = (activity: ActivityValidator):AdapterActivityValidator => {
  const report = activity?.reports?.shift() ?? null;

  return {
    activity: {
      description: activity.description,
      logo: activity.logo,
      title: activity.title,
      value: activity.value
    },
    report: {
      comments: report?.comments ?? '',
      date: report?.date ?? '',
      photo: report?.photo ?? '',
      status: report?.status ?? 0,
      studentName: report?.name ?? '',
      time: report?.time ?? ''
    }
  }
};
