<template>
  <ion-modal ref="modal" class="modalMessage fullscreen" :is-open="isOpen">
    <ion-content class="ion-padding message-content" :fullscreen="true">
      <div class="message message-container">
        <HelpTooltip
          :message="`
                <div style='text-align:center'>
                  <h1 class='help-message-description'>En esta pantalla podrás visualizar la posición de tu grupo y los puntos obtenidos.</h1>
                </div>
                `"
          :close-modal="handleClosePopOver"
          :is-open="handleOpenPopOver"
        />
        <ion-button class="close-button-modal" @click="handleModalCloseModal">
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </ion-button>
        <ion-grid class="ion-padding" :fixed="true">
          <ion-row>
            <ion-col size="12">
              <span class="left-header-text">TOP</span>
              <font-awesome-icon icon="fa-solid fa-medal" />
            </ion-col>
          </ion-row>
          <ion-row v-for="position in getPositions" :key="position.index" class="ion-margin">
            <ion-col class="position">{{ position.index }}</ion-col>
            <ion-col class="group">{{ position.group }}</ion-col>
            <ion-col class="score">{{ position.score }}</ion-col>
          </ion-row>
        </ion-grid>
        <div class="container-cubix">
          <div class="cubix">
            <img :src="images.cubix" loading="lazy" />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { IonGrid, IonRow, IonCol, IonContent, useIonRouter, IonModal, IonButton } from '@ionic/vue';
import images from '@/shared/images';
import { useStore } from 'vuex';
import { getResultPositionsXT } from '@/services/public.service';
import { useFetchAndLoad } from '@/hooks';
import { useField } from 'vee-validate';
import { createPositionResultAdapter } from '@/adapters';
import { PositionResult } from '@/models';
import HelpTooltip from '../components/HelpTooltip.vue';
import messages from '@/shared/messages';
import { setCookie } from 'typescript-cookie';
import { PAGES_KEYS } from '@/shared/pages-keys';

export default defineComponent({
  name: 'PositionResultsModal',
  components: {
    IonGrid,
    IonContent,
    IonRow,
    IonCol,
    IonModal,
    IonButton,
    HelpTooltip
  },
  setup(props, context) {
    const store = useStore();
    const router = useRoute();
    const ionRouter = useIonRouter();
    const imgPreviewSrc = ref('');
    const fileInput = ref<File | null>();
    const activityId = `${router.params.id}`;
    const { callEndpoint } = useFetchAndLoad();
    const number_activity = ref<string>('');
    const description_activity = ref<string>('');
    const relationship_activity = ref<string>('');
    const hideActivityDescription = ref<boolean>(false);
    const positions = ref<PositionResult>();
    const isOpen = ref<boolean>(false);
    const refModalMessage = ref<null | { handleOpenModal: () => null; handleCloseModal: () => null }>(null);

    const handleModalCloseModal = () => {
      isOpen.value = false;
    };

    const handleOpenPopOver = computed(() => store.getters[`general/getPage`](PAGES_KEYS.POSITIONS_RESULTS).showHelp);

    const handleClosePopOver = () => {
      setCookie(`page-${PAGES_KEYS.POSITIONS_RESULTS}`, 0);
      store.commit('general/checkPages');
    };

    const handleModalOpenModal = () => {
      isOpen.value = true;
    };

    const getPositions = computed(() => {
      return positions.value?.rankList ?? [];
    });

    const validateField = (value: any) => {
      if (!value) {
        return 'el campo es requerido';
      }

      if (`${value}`.length < 10) {
        return 'el campo debe contener 10 digitos';
      }

      if (!value.match(/^[0-9]{10}$/g)) {
        return 'es necesario ingresar un número telefónico correcto de 10 digitos';
      }

      if (value) {
        number_activity.value = `${value}`;
      }

      return true;
    };

    const { value, errorMessage } = useField('number_activity', validateField);

    onMounted(async () => {
      const dataPositionResults = {
        phaseId: localStorage.getItem('idPhase') ?? '',
        enrollmentId: localStorage.getItem('idInscription') ?? ''
      };

      const { data } = await callEndpoint(getResultPositionsXT(dataPositionResults));
      store.commit('positionResults/setPositionResults', { data: createPositionResultAdapter(data) });
      positions.value = data;
    });

    let timeOut = ref<number>();

    const toActivities = () => {
      clearTimeout(timeOut.value);
      ionRouter.navigate({ name: 'ACTIVITIES' }, 'forward', 'replace');
    };

    const handleHideDescription = () => {
      hideActivityDescription.value = true;
    };

    store.commit('activities/setSelectedActivity', { data: activityId });

    context.expose({ handleModalOpenModal, handleModalCloseModal });

    return {
      activityId,
      description_activity,
      errorMessage,
      fileInput,
      getPositions,
      handleHideDescription,
      handleModalCloseModal,
      handleModalOpenModal,
      hideActivityDescription,
      images,
      imgPreviewSrc,
      isOpen,
      number_activity,
      relationship_activity,
      refModalMessage,
      toActivities,
      handleOpenPopOver,
      handleClosePopOver,
      value,
      messages
    };
  }
});
</script>

<style lang="scss" scoped>
.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

ion-grid > ion-row:not(:nth-child(2)) ion-col {
  background-color: rgb(243, 242, 240);
}

ion-grid > ion-row:not(:nth-child(2)):hover ion-col {
  background-color: darken(rgb(243, 242, 240), 15%);
}

ion-grid > ion-row:nth-child(2) ion-col {
  background-color: #68c0e8;
  color: #fff;
}

ion-grid > ion-row:nth-child(2):hover ion-col {
  background-color: darken(#68c0e8, 15%);
}

ion-grid > ion-row:nth-child(1) {
  display: flex;
  justify-content: space-between;
}

ion-grid > ion-row:nth-child(1) ion-col {
  background-color: transparent;
  display: flex;
  border: none;
  justify-content: space-around;
}

ion-grid > ion-row:nth-child(1):hover ion-col {
  background-color: transparent;
  cursor: pointer;
}

.ion-margin-vertical {
  margin-bottom: inherit;
}

ion-col {
  border: solid 1px #fff;
  color: #fff;
  text-align: center;
  color: #51316c;
  font-size: 22px;
  font-weight: bold;
}

ion-col.group {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

ion-col.position {
  border-radius: 10px 0 0 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

ion-col.score {
  border-radius: 0 10px 10px 0;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

ion-row:hover ion-col {
  cursor: pointer;
  background-color: darken(#68c0e8, 14%);
}

.fa-medal {
  color: rgb(212, 204, 63);
  font-size: 32px;
}

.left-header-text {
  font-size: 32px;
}

.btn-close-modal {
  position: absolute;
  top: 15px;
  right: 20px;
}

.container-cubix {
  position: absolute;
  bottom: -40% !important;
  right: -60px;
}
</style>
