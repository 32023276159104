<!-- eslint-disable vue/no-v-html -->
<template>
    
    <div :id="triggerId">
        <slot name="content"></slot>
    </div>
    <ion-popover
        :class="props.class"
        :size="size"
        side="top"
        :is-open="props.isOpen"
        :dismiss-on-select="true"
        :backdrop-dismiss="true"
        :trigger="triggerId"
        :show-backdrop="true"
        :alignment="props.alignment"
        @did-dismiss="close"
    >
        <ion-content class="ion-padding">
            <div v-html="props.message"></div>
        </ion-content>
    </ion-popover>
</template>

<script lang="ts">
    import { defineComponent, ref} from 'vue';
    import { 
        IonContent,
        IonPopover,
      } from '@ionic/vue';
      import { v4 as uuidv4 } from 'uuid';

    export default defineComponent({
      name: 'HelpTooltip',
      components: { 
        IonContent,
        IonPopover
      },
      props:{
            position: { type: String, required: false },
            message: { type: String, required: true, default: '' },
            alignment: { type: String, required: false, default: 'center' },
            size: { type: String, required: false, default: 'cover' },
            isOpen: {type:Boolean, required: false, default: true},
            closeModal: { type: Function, required: true},
            class: {type: String, required: false, default: ''}
        },
      setup(props) {
        const triggerId = ref(uuidv4());

        return {
          close: () => props.closeModal(),
          triggerId,
          props
        }
      },
    });
    
</script>

<style lang="scss" scoped>
 ion-popover {
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --width: 100vw;
  
}
</style>